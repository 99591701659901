import CryptoJS from 'crypto-js';

export const encryptAndStore = (data, key) => {
  try {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_ENCRYPT_AND_DECRYPT_SECRET_KEY
    );
    localStorage.setItem(key, encryptedData.toString());
    return encryptedData.toString();
  } catch (error) {
    console.error("Encryption error:", error);
    localStorage.removeItem(key); // Remove the item if encryption fails
    return null;
  }
};

export const decryptAndRetrieve = (key) => {
  try {
    const encryptedData = localStorage.getItem(key);
    if (encryptedData) {
      const decryptedBytes = CryptoJS.AES.decrypt(
        encryptedData.toString(),
        process.env.REACT_APP_ENCRYPT_AND_DECRYPT_SECRET_KEY
      );
      const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
      if (!decryptedData) {
        throw new Error("Decryption failed or data is corrupted.");
      }
      return JSON.parse(decryptedData);
    }
    return null;
  } catch (error) {
    console.error("Decryption error:", error);
    localStorage.removeItem(key); // Remove the item if decryption fails
    return null;
  }
};
