import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';



const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  favoriteWorkers: [], 
  totalFavoriteWorkers: 0, 
};



/////////////////// Update Favorite Worker ///////////////////
export const updateFavoriteWorker = createAsyncThunk(
  'favorites/updateFavoriteWorker',
  async (workerId, thunkAPI) => {
    try {
      
      const token = thunkAPI.getState().auth.user.token;
    
    
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/updateFavoriteWorkers`;

      const response = await axios.post(API_URL, { workerId }, config);
      return response.data; 
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.response.data.status ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);



/////////////////// Fetch Favorite Workers ///////////////////
export const fetchFavorites = createAsyncThunk(
    'favorites/fetchFavorites',
    async (currentPage, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token;
  
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
  
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/getFavoriteWorkersByPageNum/${currentPage}`;
  
        const response = await axios.get(API_URL, config);
        return response.data
      } catch (error) {
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.response.data.status ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);





export const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
      reset: (state) => {
          state.isLoading = false;
          state.isSuccess = false;
          state.isError = false;
          state.message = "";
      },
  },
  extraReducers: (builder) => {
      builder
      /////////////////// Fetch Favorite Workers ///////////////////
      .addCase(fetchFavorites.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFavorites.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        state.favoriteWorkers = action.payload.favoriteWorkers;
        state.totalFavoriteWorkers = action.payload.totalFavoriteWorkers;
      })
      .addCase(fetchFavorites.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      /////////////////// Update Favorite Worker ///////////////////
      .addCase(updateFavoriteWorker.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateFavoriteWorker.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
      })
      .addCase(updateFavoriteWorker.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });

  },
});

export const { reset } = favoritesSlice.actions;
export default favoritesSlice.reducer;
