import { configureStore } from '@reduxjs/toolkit';
import workerSliceReducer from '../features/WorkerRegistrationSlice';
import authSliceReducer from '../features/AuthSlice';
import adminReducer from '../features/AdminSlice';
import { locationReducer } from '../features/locationSlice';
import PaymentMethodSlice from '../features/PaymentMethod';
import trackingReducer from '../features/TrackingFori';
import bookingSlice from '../features/Booking';
import donationSlice from "../features/DonationSomalia";
import favoritesReducer from '../features/favoritesSlice'

export const store = configureStore({
  reducer: {
    worker: workerSliceReducer,
    auth: authSliceReducer,
    location: locationReducer,
    admin: adminReducer,
    tracking: trackingReducer,
    paymentMethod: PaymentMethodSlice,
    booking: bookingSlice,
    donation: donationSlice,
    favorites: favoritesReducer,
  },
});