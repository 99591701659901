import React, { useEffect } from 'react';
import { Card, Alert, Row, Col, Typography, Space, Divider } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { getPaymentStatus } from '../../features/DonationSomalia';
import './DonateSomaliaSuccess.css';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const { Title, Text, Paragraph } = Typography;

const DonateSomaliaSuccess = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const session_id = searchParams.get('session_id');

    useEffect(() => {
        if (session_id) {
            dispatch(getPaymentStatus(session_id));
        }
    }, [dispatch, session_id]);
    useEffect(() => {
        const timer = setTimeout(() => {
            window.location.href = `/donate`;
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const root = document.querySelector('#root');
        if (root) {
            root.querySelector('.Bannerheader')?.style.setProperty('display', 'none');
            root.querySelector('.Footer')?.style.setProperty('display', 'none');
            root.querySelector('.Mob_Navbar')?.style.setProperty('display', 'none');
        }
    }, []);

    return (
        <div className="success-page">
            <Row justify="center">
                <Col xs={24} sm={20} md={16} lg={12}>
                    <div className="success-container">
                        <div className="success-message">
                            <CheckCircleFilled className="success-icon" />
                            <Title level={2}>Thank you for contributing towards digital inclusion of the next billion people.</Title>
                            <Paragraph>
                                Humbly partnering to economically empower masses through their digital inclusion.
                            </Paragraph>
                        </div>


                        <Card className="donation-card">
                            <Alert
                                message="Payment Status"
                                description="Your payment has been processed successfully."
                                type="success"
                                showIcon
                            />
                        </Card>


                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default DonateSomaliaSuccess;