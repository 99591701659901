import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { getUser, logout } from "../../features/AuthSlice";
import { getAllChatsOfTheUser } from '../../features/AuthSlice';
import Select from "react-select";
import { useCallback, useEffect, useRef, useState } from "react";
import { ChevronDown } from "lucide-react";
import axios from "axios";
import "./Header.css";

// Components
import HeaderAddressModal from "../HeaderAddressModal/HeaderAddressModal";

// Assets
import fori_logo from "../../Assets/Images/fori Logo.jpg";
import { otherIcons } from "../../Assets/Data/otherIcons";

// Actions
import { getAllSkills } from "../../features/AdminSlice";
import { setLocation } from "../../features/locationSlice";
import { getBookingsByWorkerID } from "../../features/Booking";
import NotificationPanel from "../TabbedNotificationPanel/TabbedNotificationPanel";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationRef = useRef(null);
  const dropdownRef = useRef(null);

  // Selectors
  const { user } = useSelector((store) => store.auth);
  const { allSkills } = useSelector((store) => store.admin);
  const { workerBookings } = useSelector((store) => store.booking);
  const storeLocation = useSelector((store) => store.location.location);
  const textualAddress = useSelector((store) => store.location.textual_address);
  const chat = useSelector((store) => store.auth.chat);

  // State
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [addressUnderIcon, setAddressUnderIcon] = useState('');
  const [selectedOption, setSelectedOption] = useState("");

  // Initialize Geocode and fetch user data
  useEffect(() => {
    dispatch(getUser());
    dispatch(getAllSkills());
  }, [dispatch]);

  // Fetch worker bookings
  const fetchBookings = useCallback(() => {
    if (user?.workerId) {
      dispatch(getBookingsByWorkerID(user.workerId));
    }
  }, [dispatch, user]);

  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  // Fetch chats
  useEffect(() => {
    if (user) {
      dispatch(getAllChatsOfTheUser(user.id));
    }
  }, [dispatch, user]);

  // Process notifications
  useEffect(() => {
    if (user?.workerId && workerBookings?.bookings) {
      const now = new Date();
      const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

      const pendingBookings = workerBookings.bookings.filter(
        booking => booking.jobStatus === "pending" &&
          new Date(booking.bookingCreatedAt) > sevenDaysAgo
      );

      const newNotifications = pendingBookings.map(booking => ({
        id: booking._id,
        message: `New booking request for ${booking.skill}`,
        createdAt: new Date(booking.bookingCreatedAt)
      }));

      setNotifications(newNotifications.sort((a, b) => b.createdAt - a.createdAt));
    } else {
      setNotifications([]);
    }
  }, [user, workerBookings]);

  useEffect(() => {
    const fetchLocation = async () => {
      if (Object.keys(storeLocation).length === 0 && Object.keys(textualAddress).length === 0) {
        if (!navigator.geolocation) {
          toast.error("Geolocation is not supported by this browser.");
          return;
        }

        navigator.geolocation.getCurrentPosition(async (position) => {
          const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };

          try {
            const apiKey = process.env.REACT_APP_LOCATIONIQ_API_KEY;
            const response = await axios.get(
              `https://us1.locationiq.com/v1/reverse.php?key=${apiKey}&lat=${location.latitude}&lon=${location.longitude}&format=json`
            );

            if (response.data) {
              // Create a formatted address object similar to OpenCage structure
              const formattedAddress = {
                components: {
                  _normalized_city: response.data.address.city || response.data.address.town,
                  district: response.data.address.suburb || response.data.address.district,
                  town: response.data.address.city_district || response.data.address.town
                },
                formatted: response.data.display_name
              };

              dispatch(setLocation({ location, address: formattedAddress }));
            } else {
              toast.error("Could not get location details");
            }
          } catch (error) {
            console.error('Error fetching location:', error);
            toast.error("Error getting location details");
          }
        },
          (error) => {
            console.error('Geolocation error:', error);
            toast.error("Error accessing location. Please check your browser permissions.");
          });
      }
    };

    fetchLocation();
  }, [dispatch, storeLocation, textualAddress]);

  useEffect(() => {
    if (textualAddress) {
      // Try to get the most specific location name available
      const city = textualAddress.components._normalized_city ||
        textualAddress.components.district ||
        textualAddress.components.town ||
        'Location';
      setAddressUnderIcon(`${city}...`);
    }
  }, [textualAddress]);

  // Click outside handlers
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!notificationRef.current?.contains(event.target)) {
        setShowNotifications(false);
      }
      if (!dropdownRef.current?.contains(event.target)) {
        setIsUserDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Handlers
  const handleRegisterClick = () => {
    if (!user) {
      toast("Please sign in to register a worker");
    }
  };

  const handleSkillChange = (selectedOption) => {
    if (selectedOption) {
      navigate(`/skill?q=${encodeURIComponent(selectedOption.value)}&exact=true`);
    }
  };

  const handleNotificationClick = () => {
    setShowNotifications(false);
    navigate('/bookings/availableBookings');
  };

  const handleLogout = () => {
    localStorage.removeItem('becomeAWorkerData');
    localStorage.removeItem('addAWorkerData');
    dispatch(logout()).then(() => navigate("/signin"));
    setIsUserDropdownOpen(false);
    localStorage.removeItem('counterAnimationPlayed');
  };

  const getTimeAgo = (date) => {
    const now = new Date();
    const diffInMinutes = Math.floor((now - date) / (1000 * 60));

    if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
    }
    if (diffInMinutes < 24 * 60) {
      const diffInHours = Math.floor(diffInMinutes / 60);
      return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
    }
    const diffInDays = Math.floor(diffInMinutes / (24 * 60));
    return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
  };

  const pathName = window.location.pathname;
  const selectOptions = allSkills.map(option => ({
    value: option.name,
    label: option.name
  }));

  console.log(user, 'user');


  return (
    <div className="Bannerheader">
      <div className="header_left">
        <Link to="/">
          <img src={fori_logo} alt="Fori Logo" />
        </Link>
        <div className="header_right flex_row">
          <NavLink
            className={pathName === '/' ? "activeLink" : "non_active"}
            to="/"
          >
            HOME
          </NavLink>

          <NavLink
            className={pathName === '/about' ? "activeLink" : "non_active"}
            to="/about"
          >
            ABOUT
          </NavLink>
          {user?.workerId && (
            <NavLink to="/Bookings/profile">
              MY PROFILE
            </NavLink>
          )}
          {user && (
            <div className="dropdown">
              <NavLink className="dropbtn">
                DASHBOARD
                <ChevronDown className="dropdown-arrow" size={16} />
              </NavLink>

              <div className="dropdown-content">

                <NavLink to="/Bookings">
                  BOOKINGS
                </NavLink>
                <NavLink to="/Bookings/createBroadcastJob">
                  BROADCAST JOB
                </NavLink>
                <NavLink to="/favorites">
                  FAVORITES
                </NavLink>
              </div>
            </div>
          )}

          <NavLink
            className={pathName.startsWith('/skills') ? "activeLink" : "non_active"}
            to="/skills"
          >
            SKILLS
          </NavLink>

          <div className="dropdown">
            <NavLink className="dropbtn">
              WORKER REGISTRATION
              <ChevronDown className="dropdown-arrow" size={16} />
            </NavLink>
            <div className="dropdown-content">
              {!user?.workerId && (
                <NavLink
                  to={user ? "/register-as-worker" : "/signin"}
                  onClick={handleRegisterClick}
                >
                  BECOME A WORKER
                </NavLink>
              )}



              <NavLink
                to={user ? "/addAWorker" : "/signin"}
                onClick={handleRegisterClick}
              >
                ADD A WORKER
              </NavLink>
              <NavLink
                to={user ? "/register-multiple-workers" : "/signin"}
                onClick={handleRegisterClick}
              >
                ADD MULTIPLE WORKERS
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="flex_row_center">
        <div className="search_Worker_Field">
          <Select
            value={selectedOption}
            onChange={(option) => {
              setSelectedOption(option);
              handleSkillChange(option);
            }}
            options={selectOptions}
            placeholder="Search a Worker Here"
            styles={{ container: (provided) => ({ ...provided, width: '100%' }) }}
          />
        </div>

        {user && (
          <NotificationPanel
            notifications={notifications}
            chats={chat || []}
            handleNotificationClick={handleNotificationClick}
            getTimeAgo={getTimeAgo}
            user={user}
          />
        )}

        <div className="location location_heading">
          <button onClick={() => setIsAddressModalOpen(true)}>
            {otherIcons.locationIcon}
          </button>
          <h6 className="addressUnderIcon">
            {addressUnderIcon || 'Loading...'}
          </h6>

          {isAddressModalOpen && (
            <HeaderAddressModal
              open={isAddressModalOpen}
              onClose={() => setIsAddressModalOpen(false)}
              address={address}
              setAddress={setAddress}
            />
          )}
        </div>

        {user ? (
          <div className="avatar-container" ref={dropdownRef}>
            <button
              onClick={() => setIsUserDropdownOpen(!isUserDropdownOpen)}
              className="avatar-button"
              aria-label="User menu"
            >
              {user.name?.charAt(0).toUpperCase() || 'U'}
            </button>

            {isUserDropdownOpen && (
              <div className="dropdownn">
                <div className="user-info">
                  <p className="user-name">
                    <strong>{user.name}</strong>
                  </p>
                  {user.email && (
                    <p className="user-email">
                      {user.email}
                    </p>
                  )}
                  {user.phone && (
                    <p className="user-phone">
                      {user.phone}
                    </p>
                  )}
                  <p className="user-role">
                    {user.workerId ? "User, Worker" : "User"}
                  </p>
                </div>

                <nav className="dropdown-menu">
                  <Link
                    to="/favorites"
                    className="dropdown-item"
                    onClick={() => setIsUserDropdownOpen(false)}
                  >
                    Favorites
                  </Link>

                  {user?.workerId && (
                    <Link
                      to="/Bookings/profile"
                      className="dropdown-item"
                      onClick={() => setIsUserDropdownOpen(false)}
                    >
                      My Worker Profile
                    </Link>
                  )}

                  <Link
                    to="/Bookings/allPaymentMethods"
                    className="dropdown-item"
                    onClick={() => setIsUserDropdownOpen(false)}
                  >
                    Payment Methods
                  </Link>
                </nav>

                <button className="logOut_btn" onClick={handleLogout}>
                  {otherIcons.logOut}
                  <>Logout</>
                </button>
              </div>
            )}
          </div>
        ) : (
          <Link className="btn" to="/signin">
            SIGN IN
          </Link>
        )}
      </div>
    </div>
  );
};

export default Header;