import React from 'react';
import './Footer.css';
import { FaFacebookSquare } from "react-icons/fa";
import { BsArrowUpRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { BsInstagram, BsLinkedin } from "react-icons/bs";
import { MdMail } from "react-icons/md";
function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0;
}

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <div className='Footer site_padding'>
      <div>

        <div className='Footer_bottom'>
          <h6 onClick={topFunction}>Go To Top<BsArrowUpRight /></h6>
          
        </div>

        <div className='footer_links'>
          <div className='links_column'>
          <h5><Link to="/policy">Help & Support</Link></h5>
          <h5><Link to="/accessibility">Privacy Policy</Link></h5>
          <h5><Link to="/policy">Accessibility</Link></h5>
          </div>
          <div className='links_column'>
          <h5><Link to="/about">About</Link></h5>
          <h5><Link to="/skills">Skills</Link></h5>
          <h5><Link to="/signin">Become a Member</Link></h5>
          </div>
          <div className='links_column'>
          <h5><Link to="/addAWorker">Become Worker</Link></h5>
          <h5><Link to="/register-multiple-workers">Add Multiple Workers</Link></h5>
          <h5><Link to="/contact">Feedback</Link></h5>
          </div>
          <div className='links_column'>
          <h5><Link to="/">Home</Link></h5>
          <h5><Link to="/contact">Contact Us</Link></h5>
          </div>
        </div>

        
        <div className='followus_links_upper'>
          {/* <div className='flex_row gap1rem followus_links'>
            <h5><Link to="/policy">Privacy Policy</Link></h5>
            <h5><Link to="/accessibility">Accessibility</Link></h5>
          </div> */}
          <h5>Follow us at</h5>
          <div className='followus_links'>
            <a
              className="icons-a"
              href="https://instagram.com/fori_inc?igshid=NzZhOTFlYzFmZQ=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsInstagram className="icons-circle" style={{ fontSize: '17px' }} />
            </a>
            <a
              className="icons-a"
              href="https://www.linkedin.com/company/foriinc/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsLinkedin className="icons-circle" style={{ fontSize: '17px' }} />
            </a>
            <a
              className="icons-a"
              href="mailto:ceo@fori.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MdMail className="icons-circle" style={{ fontSize: '20px' }} />
            </a>
            <a
              className="icons-a"
              href="https://web.facebook.com/ForiMazdoori/?_rdc=1&_rdr#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookSquare  className="icons-circle" style={{ fontSize: '17px' }} />
            </a>
          </div>
        </div>

        
      <div className='footer_copyrightText'>
      <span className='footer_tag'> {currentYear} &copy; Copyrights Fori Inc. All Rights Reserved</span>
{/* 
<h6 className='followus'>Connect With Us <HiOutlineShare /></h6> */}
      </div>
      </div>
    </div>
  )
}
export default Footer;